



// import React, { useState } from 'react';
// import { Box, TextField, Button, Typography, Grid, Container } from '@mui/material';
// import PhoneIcon from '@mui/icons-material/Phone';
// import EmailIcon from '@mui/icons-material/Email';
// import LocationOnIcon from '@mui/icons-material/LocationOn';
// import aboutusbg from '../assets/images/contactus.jpg';
// import emailjs from 'emailjs-com'; 
// import { toast, ToastContainer } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';

// const ContactPage = () => {
//   const [formData, setFormData] = useState({
//     name: '',
//     email: '',
//     phone: '',
//     message: '',
//   });

//   const handleChange = (e) => {
//     setFormData({ ...formData, [e.target.name]: e.target.value });
//   };

//   const handleSubmit = (e) => {
//     e.preventDefault();
//     emailjs
//       .send(
//         'service_bk75d28',
//         'template_zmpvrdg',
//         formData,
//         'VmNEUQFxcyBXZFRP2'
//       )
//       .then(
//         (response) => {
//           console.log('SUCCESS!', response.status, response.text);
//           toast.success('Email sent successfully!', {
//             position: 'top-right',
//           });
//         },
//         (error) => {
//           console.log('FAILED...', error);
//           toast.error('Failed to send email. Please try again later.', {
//             position: 'top-right', 
//           });
//         }
//       );
//   };
  

//   return (
//     <Box sx={{ minHeight: '100vh', display: 'flex', flexDirection: 'column' }}>
//       <ToastContainer /> 
//       <Box
//         sx={{
//           position: 'relative',
//           backgroundImage: `url(${aboutusbg})`,
//           height: '500px',
//           textAlign: 'center',
//           backgroundPosition: 'center',
//           backgroundRepeat: 'no-repeat',
//           backgroundSize: 'cover',
//           display: 'flex',
//           alignItems: 'center',
//           justifyContent: 'center',
//           '&::before': {
//             content: '""',
//             position: 'absolute',
//             top: 0,
//             left: 0,
//             width: '100%',
//             height: '100%',
//             backgroundColor: 'rgb(0 0 0 / 28%)',
//           },
//         }}
//       >
//         <Box sx={{ textAlign: 'center', p: 2 }}>
//           <Typography
//             variant="h4"
//             color="white"
//             gutterBottom
//             sx={{
//               fontFamily: '"Poppins", "Arial", sans-serif',
//               fontWeight: 'bold',
//               letterSpacing: 0.3,
//               textTransform: 'uppercase',
//               position: 'relative',
//               fontSize: '35px',
//               color: '#fff',
//               textShadow: '0px 2px 5px rgba(0, 0, 0, 0.1)',
//               '@media (max-width: 767px)': {
//                 fontSize: '18px',
//               },
//             }}
//           >
//             We're Here to Help!
//           </Typography>
//           <Typography
//             variant="h6"
//             sx={{
//               position: 'relative',
//               fontWeight: 'bold',
//               color: '#fff',
//               fontFamily: '"Montserrat", "Arial", sans-serif',
//               fontSize: '18px',
//             }}
//           >
//             Feel free to reach out with any inquiries.
//           </Typography>
//         </Box>
//       </Box>

//       <Container>
//         <Grid container spacing={3} sx={{ backgroundColor: '#aebdd5', padding: '50px 50px 50px 24px', borderRadius: '10px', margin: '50px 0px' }}>
//           <Grid item xs={12} md={6} sm={6}>
//             <Typography
//               variant="h4"
//               color="primary"
//               gutterBottom
//               sx={{
//                 fontFamily: '"Poppins", "Arial", sans-serif',
//                 fontWeight: 'bold',
//                 letterSpacing: 0.3,
//                 textTransform: 'uppercase',
//                 position: 'relative',
//                 fontSize: '24px',
//                 color: '#fff',
//                 textShadow: '0px 2px 5px rgba(0, 0, 0, 0.1)',
//                 '@media (max-width: 767px)': {
//                   fontSize: '18px',
//                 },
//               }}
//             >
//               Get In Touch
//             </Typography>

//             <form onSubmit={handleSubmit}>
//               <TextField
//                 label="Name"
//                 name="name"
//                 fullWidth
//                 variant="outlined"
//                 margin="normal"
//                 value={formData.name}
//                 onChange={handleChange}
//                 InputLabelProps={{
//                   sx: {
//                     '&.Mui-focused': {
//                       color: '#d32f2f',
//                     },
//                   },
//                 }}
//                 sx={{
//                   backgroundColor: '#fff',
//                   borderRadius: 1,
//                   '& .MuiOutlinedInput-root': {
//                     color: 'white',
//                     '& fieldset': {
//                       borderColor: '#4a5568',
//                       transition: 'border-color 0.3s ease',
//                     },
//                     '&:hover fieldset': {
//                       borderColor: '#d32f2f',
//                     },
//                     '&.Mui-focused fieldset': {
//                       borderColor: '#d32f2f',
//                     },
//                   },
//                 }}
//                 InputProps={{ style: { color: '#242424' } }}
//               />

//               <TextField
//                 label="Email"
//                 name="email"
//                 fullWidth
//                 variant="outlined"
//                 margin="normal"
//                 value={formData.email}
//                 onChange={handleChange}
//                 sx={{
//                   backgroundColor: '#fff',
//                   borderRadius: 1,
//                   '& .MuiOutlinedInput-root': {
//                     color: '#242424',
//                     '& fieldset': {
//                       borderColor: '#4a5568',
//                       transition: 'border-color 0.3s ease',
//                     },
//                     '&:hover fieldset': {
//                       borderColor: '#d32f2f',
//                     },
//                     '&.Mui-focused fieldset': {
//                       borderColor: '#d32f2f',
//                     },
//                   },
//                 }}
//                 InputLabelProps={{
//                   sx: {
//                     '&.Mui-focused': {
//                       color: '#d32f2f',
//                     },
//                   },
//                 }}
//                 InputProps={{ style: { color: '#242424' } }}
//               />

//               <TextField
//                 label="Phone Number"
//                 name="phone"
//                 fullWidth
//                 variant="outlined"
//                 margin="normal"
//                 value={formData.phone}
//                 onChange={handleChange}
//                 sx={{
//                   backgroundColor: '#fff',
//                   borderRadius: 1,
//                   '& .MuiOutlinedInput-root': {
//                     color: 'white',
//                     '& fieldset': {
//                       borderColor: '#4a5568',
//                       transition: 'border-color 0.3s ease',
//                     },
//                     '&:hover fieldset': {
//                       borderColor: '#d32f2f',
//                     },
//                     '&.Mui-focused fieldset': {
//                       borderColor: '#d32f2f',
//                     },
//                   },
//                 }}
//                 InputLabelProps={{
//                   sx: {
//                     '&.Mui-focused': {
//                       color: '#d32f2f',
//                     },
//                   },
//                 }}
//                 InputProps={{ style: { color: '#242424' } }}
//               />

//               <TextField
//                 label="Your Message"
//                 name="message"
//                 fullWidth
//                 variant="outlined"
//                 margin="normal"
//                 multiline
//                 rows={4}
//                 value={formData.message}
//                 onChange={handleChange}
//                 sx={{
//                   backgroundColor: '#fff',
//                   borderRadius: 1,
//                   '& .MuiOutlinedInput-root': {
//                     color: 'white',
//                     '& fieldset': {
//                       borderColor: '#4a5568',
//                       transition: 'border-color 0.3s ease',
//                     },
//                     '&:hover fieldset': {
//                       borderColor: '#d32f2f',
//                     },
//                     '&.Mui-focused fieldset': {
//                       borderColor: '#d32f2f',
//                     },
//                   },
//                 }}
//                 InputLabelProps={{
//                   sx: {
//                     '&.Mui-focused': {
//                       color: '#d32f2f',
//                     },
//                   },
//                 }}
//                 InputProps={{ style: { color: '#242424' } }}
//               />

//               <Button
//                 type="submit"
//                 variant="contained"
//                 fullWidth
//                 sx={{
//                   fontSize: '16px',
//                   backgroundColor: '#d32f2f',
//                   color: 'white',
//                   '&:hover': {
//                     backgroundColor: '#c62828',
//                   },
//                 }}
//               >
//                 Send Message
//               </Button>
//             </form>
//           </Grid>

//           <Grid item xs={12} md={6} sm={6}>
//             <Typography
//               variant="h4"
//               color="primary"
//               gutterBottom
//               sx={{
//                 fontFamily: '"Poppins", "Arial", sans-serif',
//                 fontWeight: 'bold',
//                 letterSpacing: 0.3,
//                 textTransform: 'uppercase',
//                 position: 'relative',
//                 fontSize: '24px',
//                 color: '#fff',
//                 textShadow: '0px 2px 5px rgba(0, 0, 0, 0.1)',
//                 '@media (max-width: 767px)': {
//                   fontSize: '18px',
//                 },
//               }}
//             >
//               Contact Information
//             </Typography>
//             <Typography
//               variant="h6"
//               color="primary"
//               gutterBottom
//               sx={{
//                 fontFamily: '"Poppins", "Arial", sans-serif',
//                 fontWeight: 'bold',
//                 letterSpacing: 0.3,
//                 color: '#fff',
//               }}
//             >
//               <PhoneIcon /> +1 234 567 890
//             </Typography>
//             <Typography
//               variant="h6"
//               color="primary"
//               gutterBottom
//               sx={{
//                 fontFamily: '"Poppins", "Arial", sans-serif',
//                 fontWeight: 'bold',
//                 letterSpacing: 0.3,
//                 color: '#fff',
//               }}
//             >
//               <EmailIcon /> example@example.com
//             </Typography>
//             <Typography
//               variant="h6"
//               color="primary"
//               gutterBottom
//               sx={{
//                 fontFamily: '"Poppins", "Arial", sans-serif',
//                 fontWeight: 'bold',
//                 letterSpacing: 0.3,
//                 color: '#fff',
//               }}
//             >
//               <LocationOnIcon /> 123 Main St, City, Country
//             </Typography>
//           </Grid>
//         </Grid>
//       </Container>
//     </Box>
//   );
// };

// export default ContactPage;


// import React, { useState } from 'react';
// import { Box, TextField, Button, Typography, Grid, Paper, Container } from '@mui/material';
// import PhoneIcon from '@mui/icons-material/Phone';
// import EmailIcon from '@mui/icons-material/Email';
// import LocationOnIcon from '@mui/icons-material/LocationOn';
// import aboutusbg from '../assets/images/contactus.jpg'

// const ContactPage = () => {
//   const [formData, setFormData] = useState({
//     name: '',
//     email: '',
//     phone: '',
//     message: '',
//   });

//   const handleChange = (e) => {
//     setFormData({ ...formData, [e.target.name]: e.target.value });
//   };

//   const handleSubmit = (e) => {
//     e.preventDefault();
//     console.log('Form Data:', formData);
//   };

//   return (
    
//     <Box
//       sx={{
//         minHeight: '100vh',
//         display: 'flex',
//         flexDirection: 'column',
//       }}
//     >
//      <Box
//         sx={{
//           position: 'relative',
//           backgroundImage: `url(${aboutusbg})`,
//           height: '500px',
//           textAlign: 'center',
//           backgroundPosition: 'center',
//           backgroundRepeat: 'no-repeat',
//           backgroundSize: 'cover',
//           display: 'flex',
//           alignItems: 'center',
//           justifyContent: 'center',
//           '&::before': {
//             content: '""',
//             position: 'absolute',
//             top: 0,
//             left: 0,
//             width: '100%',
//             height: '100%',
//             backgroundColor: 'rgb(0 0 0 / 28%)',
  
//           },
      
//         }}
//       >

//       <Box sx={{ textAlign: 'center', p: 2 }}>
//           <Typography variant="h4" color="white" gutterBottom sx={{
//               fontFamily: '"Poppins", "Arial", sans-serif',
//               fontWeight: 'bold',
//               letterSpacing: 0.3,
//               textTransform: 'uppercase',
//               position: 'relative',
//               fontSize: '35px',
//               color: '#fff',
//               textShadow: '0px 2px 5px rgba(0, 0, 0, 0.1)',
//               '@media (max-width: 767px)': {
//                 fontSize: '18px',
//               },
//             }}
//           >
//           We're Here to Help!
//         </Typography>
//           <Typography variant="h6"  sx={{
//             position: 'relative',
//             fontWeight: 'bold',
//             color: '#fff', 
//             fontFamily: '"Montserrat", "Arial", sans-serif', 
//             fontSize: '18px',
//           }}>
//           Feel free to reach out with any inquiries.
//         </Typography>
//       </Box>

//       </Box>
//       <Container>
//       <Grid container spacing={3} sx={{ backgroundColor: '#aebdd5', padding: '50px 50px 50px 24px', borderRadius: '10px', margin:'50px 0px' }}>
    
//           <Grid item xs={12} md={6} sm={6}>
//           <Typography
//             variant="h4"
//             color="primary"
//             gutterBottom
//             sx={{
//               fontFamily: '"Poppins", "Arial", sans-serif',
//               fontWeight: 'bold',
//               letterSpacing: 0.3,
//               textTransform: 'uppercase',
//               position: 'relative',
//               fontSize: '24px',
//               color: '#fff',
//               textShadow: '0px 2px 5px rgba(0, 0, 0, 0.1)',
//               '@media (max-width: 767px)': {
//                 fontSize: '18px',
//               },
//             }}
//           >
//             Get In Touch
//           </Typography>

//           <form onSubmit={handleSubmit}>
//             <TextField
//               label="Name"
//               name="name"
//               fullWidth
//               variant="outlined"
//               margin="normal"
//               value={formData.name}
//               onChange={handleChange}
//               InputLabelProps={{
//                 sx: {
//                   '&.Mui-focused': {
//                     color: '#d32f2f',
//                   },
//                 },
//               }}
//               sx={{
//                 backgroundColor: '#fff',
//                 borderRadius: 1,
//                 '& .MuiOutlinedInput-root': {
//                   color: 'white',
//                   '& fieldset': {
//                     borderColor: '#4a5568',
//                     transition: 'border-color 0.3s ease', 
//                   },
//                   '&:hover fieldset': {
//                     borderColor: '#d32f2f',
//                   },
//                   '&.Mui-focused fieldset': {
//                     borderColor: '#d32f2f',
//                   },
//                 },
//               }}
//               InputProps={{ style: { color: '#242424' } }}
//             />


//               <TextField
//                 label="Email"
//                 name="email"
//                 fullWidth
//                 variant="outlined"
//                 margin="normal"
//                 value={formData.email}
//                 onChange={handleChange}
//                 sx={{
//                   backgroundColor: '#fff',
//                   borderRadius: 1,
//                   '& .MuiOutlinedInput-root': {
//                     color: '#242424',
//                     '& fieldset': {
//                       borderColor: '#4a5568',
//                       transition: 'border-color 0.3s ease', 
//                     },
//                     '&:hover fieldset': {
//                       borderColor: '#d32f2f',
//                     },
//                     '&.Mui-focused fieldset': {
//                       borderColor: '#d32f2f',
//                     },
//                   },
//                 }}
//                 InputLabelProps={{
//                   sx: {
//                     '&.Mui-focused': {
//                       color: '#d32f2f',
//                     },
//                   },
//                 }}
//                 InputProps={{ style: { color: '#242424' } }}
//               />
//               <TextField
//                 label="Phone Number"
//                 name="phone"
//                 fullWidth
//                 variant="outlined"
//                 margin="normal"
//                 value={formData.phone}
//                 onChange={handleChange}
//                 sx={{
//                   backgroundColor: '#fff',
//                   borderRadius: 1,
//                   '& .MuiOutlinedInput-root': {
//                     color: 'white',
//                     '& fieldset': {
//                       borderColor: '#4a5568',
//                       transition: 'border-color 0.3s ease', 
//                     },
//                     '&:hover fieldset': {
//                       borderColor: '#d32f2f',
//                     },
//                     '&.Mui-focused fieldset': {
//                       borderColor: '#d32f2f',
//                     },
//                   },
//                 }}
//                 InputLabelProps={{
//                   sx: {
//                     '&.Mui-focused': {
//                       color: '#d32f2f',
//                     },
//                   },
//                 }}
//                 InputProps={{ style: { color: '#242424' } }}
//               />
//               <TextField
//                 label="Your Message"
//                 name="message"
//                 fullWidth
//                 variant="outlined"
//                 margin="normal"
//                 multiline
//                 rows={4}
//                 value={formData.message}
//                 onChange={handleChange}
//                 sx={{
//                   backgroundColor: '#fff',
//                   borderRadius: 1,
//                   '& .MuiOutlinedInput-root': {
//                     color: 'white',
//                     '& fieldset': {
//                       borderColor: '#4a5568',
//                       transition: 'border-color 0.3s ease', 
//                     },
//                     '&:hover fieldset': {
//                       borderColor: '#d32f2f',
//                     },
//                     '&.Mui-focused fieldset': {
//                       borderColor: '#d32f2f',
//                     },
//                   },
//                 }}
//                 InputLabelProps={{
//                   sx: {
//                     '&.Mui-focused': {
//                       color: '#d32f2f',
//                     },
//                   },
//                 }}
//                 InputProps={{ style: { color: '#242424' } }}
//               />
//               <Button
//                 type="submit"
//                 variant="contained"
//                 fullWidth
//                 sx={{
//                   fontSize: '16px',
//                   fontFamily: '"Montserrat", "Arial", sans-serif',
//                   padding: '10px 20px',
//                   borderRadius: '8px',
//                   background: '#d32f2f',
//                   fontWeight: '500',
//                   color: '#fff',
//                   marginTop: '25px',
//                   textTransform: 'none',
//                 }}>
//                 Submit
//               </Button>
//             </form>
//           </Grid>

       
//           <Grid item xs={12} md={6} sm={6}>
//             <Typography
//               variant="h4"
//               color="primary"
//               gutterBottom
//                 sx={{
//                   fontFamily: '"Poppins", "Arial", sans-serif',
//                   fontWeight: 'bold',
//                   letterSpacing: 0.3,
//                   textTransform: 'capitalize',
//                   position: 'relative',
//                   fontSize: '24px',
//                   marginBottom: '24px',
//                   color: '#fff',
//                   textShadow: '0px 2px 5px rgba(0, 0, 0, 0.1)',
//                   '@media (max-width: 767px)': {
//                     fontSize: '18px',
//                   },
//                 }}
//               >
//               For Any Queries, Contact Us!
//             </Typography>

//             <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
//               <PhoneIcon sx={{ color: "#d32f2f" }} />
//               <Typography
//                 sx={{
//                   ml: 1,
//                   color: '#fff',
//                   fontSize: '14px',
//                   letterSpacing: '0.3px'
//                 }}
//               >
//                 Call Us +1 (888) 777-0497
//               </Typography>
//             </Box>
//             <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
//               <EmailIcon sx={{ color: "#d32f2f" }} />
//               <Typography sx={{
//                 ml: 1,
//                 color: '#fff',
//                 fontSize: '14px',
//                 letterSpacing: '0.3px'
//               }}> Email Us mailto:info@carsonlineparts.com</Typography>
//             </Box>

//             <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
//               <LocationOnIcon sx={{ color: "#d32f2f" }} />
//               <Typography sx={{
//                 ml: 1,
//                 color: '#fff',
//                 fontSize: '14px',
//                 letterSpacing: '0.3px'
//               }}> Visit Here  918 146TH AVE SE Bellevue, WA 98007</Typography>
//             </Box>
//           <Box sx={{ mt: 3, height: '300px', border: '1px solid #ccc' }}>
//             <iframe
//               title="Google Map"
//               src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3153.9911533047715!2d-122.19467958468129!3d47.614054679184366!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x54906f1820684af3%3A0x4f20c1c31bcb2f9f!2s918%20146th%20Ave%20SE%2C%20Bellevue%2C%20WA%2098007%2C%20USA!5e0!3m2!1sen!2sin!4v1634778234908!5m2!1sen!2sin"
//               width="100%"
//               height="100%"
//               style={{ border: 0 }}
//               allowFullScreen=""
//               loading="lazy"
//             ></iframe>
//           </Box>
//         </Grid>
//       </Grid>
//       </Container>
//     </Box>

//   );
// };

// const textFieldStyles = {
//   backgroundColor: '#4a5568',
//   borderRadius: 1,
//   '& .MuiOutlinedInput-root': {
//     color: 'white',
//     '& fieldset': {
//       borderColor: '#4a5568',
//       transition: 'border-color 0.3s ease',
//     },
//     '&:hover fieldset': {
//       borderColor: '#d32f2f',
//     },
//     '&.Mui-focused fieldset': {
//       borderColor: '#d32f2f',
//     },
//   },
//   InputLabelProps: { style: { color: 'white' } },
//   InputProps: { style: { color: 'white' } },
// };

// const contactInfoStyles = {
//   ml: 1,
//   color: '#ddd',
//   fontSize: '14px',
//   letterSpacing: '0.3px',
// };


// export default ContactPage;




import React, { useState,useEffect} from 'react';
import { Box, TextField, Button, Typography, Grid, Container } from '@mui/material';
import { Link } from 'react-router-dom';
import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import aboutusbg from '../assets/images/contactus.jpg';
import emailjs from 'emailjs-com'; 
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const ContactPage = () => {

  useEffect(() => {
    // Dynamically add the Google Tag Manager script to the document head
    const script = document.createElement('script');
    script.src = 'https://www.googletagmanager.com/gtag/js?id=AW-16781547707';
    script.async = true;
    document.head.appendChild(script);

    script.onload = () => {
      // Initialize Google Tag Manager when the script is loaded
      /* eslint-disable no-undef */
      window.dataLayer = window.dataLayer || [];
      function gtag() { dataLayer.push(arguments); }
      gtag('js', new Date());
      gtag('config', 'AW-16781547707');
      /* eslint-enable no-undef */
    };
// Cleanup the script on component unmount
    return () => {
      document.head.removeChild(script);
    };
  }, []);
  
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    message: '',
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
  
    
    if (!formData.name || !formData.email || !formData.phone || !formData.message) {
      toast.error('Please fill all fields.', {
        position: 'top-right',
      });
      return; 
    }

    
    
  
    emailjs
      .send(
        'service_xs37ipj', 'template_ph7df3a', 
        formData,
        'TUhvHgQ0Bp4osZuvg' 
      )
      .then(
        (response) => {
          console.log('SUCCESS!', response.status, response.text);
          toast.success('Email sent successfully!', {
            position: 'top-right',
          });
        },
        (error) => {
          console.log('FAILED...', error);
          toast.error('Failed to send email. Please try again later.', {
            position: 'top-right',
          });
        }
      );
  };

  const handlePhoneClick = () => {
    window.open('tel:+1 437 739 0111'); // Opens the phone dialer
  };


  const handleEmailClick = () => {
    window.open('mailto:contact@yndautoparts.com'); // Opens the email client
  };

  const handleLocationClick = () => {
    window.open('https://www.google.com/maps/place/8316+Tenth+Line+N,+Norval,+ON+L0P+1K0,+Canada'); // Opens Google Maps
  };
  

  return (
    <Box sx={{ minHeight: '100vh', display: 'flex', flexDirection: 'column' }}>
      <ToastContainer /> 
      <Box
        sx={{
          position: 'relative',
          backgroundImage: `url(${aboutusbg})`,
          height: '500px',
          textAlign: 'center',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          '&::before': {
            content: '""',
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: 'rgb(0 0 0 / 28%)',
          },
        }}
      >
        <Box sx={{ textAlign: 'center', p: 2 }}>
          <Typography
            variant="h4"
            color="white"
            gutterBottom
            sx={{
              fontFamily: '"Poppins", "Arial", sans-serif',
              fontWeight: 'bold',
              letterSpacing: 0.3,
              textTransform: 'uppercase',
              position: 'relative',
              fontSize: '35px',
              color: '#fff',
              textShadow: '0px 2px 5px rgba(0, 0, 0, 0.1)',
              '@media (max-width: 767px)': {
                fontSize: '18px',
              },
            }}
          >
            We're Here to Help!
          </Typography>
          <Typography
            variant="h6"
            sx={{
              position: 'relative',
              fontWeight: 'bold',
              color: '#fff',
              fontFamily: '"Montserrat", "Arial", sans-serif',
              fontSize: '18px',
            }}
          >
            Feel free to reach out with any inquiries.
          </Typography>
        </Box>
      </Box>

      <Container>
        <Grid container spacing={3} sx={{ backgroundColor: '#aebdd5', padding: '50px 50px 50px 24px', borderRadius: '10px', margin: '50px 0px' }}>
          <Grid item xs={12} md={6} sm={6}>
            <Typography
              variant="h4"
              color="primary"
              gutterBottom
              sx={{
                fontFamily: '"Poppins", "Arial", sans-serif',
                fontWeight: 'bold',
                letterSpacing: 0.3,
                textTransform: 'uppercase',
                position: 'relative',
                fontSize: '24px',
                color: '#fff',
                '@media (max-width: 767px)': {
                  fontSize: '18px',
                },
              }}
            >
              Get In Touch
            </Typography>

            <form onSubmit={handleSubmit}>
              <TextField
                label="Name"
                name="name"
                fullWidth
                variant="outlined"
                margin="normal"
                value={formData.name}
                onChange={handleChange}
                InputLabelProps={{
                  sx: {
                    '&.Mui-focused': {
                      color: '#d32f2f',
                    },
                  },
                }}
                sx={{
                  backgroundColor: '#fff',
                  borderRadius: 1,
                  '& .MuiOutlinedInput-root': {
                    color: 'white',
                    '& fieldset': {
                      borderColor: '#4a5568',
                      transition: 'border-color 0.3s ease',
                    },
                    '&:hover fieldset': {
                      borderColor: '#d32f2f',
                    },
                    '&.Mui-focused fieldset': {
                      borderColor: '#d32f2f',
                    },
                  },
                }}
                InputProps={{ style: { color: '#242424' } }}
              />

              <TextField
                label="Email"
                name="email"
                fullWidth
                variant="outlined"
                margin="normal"
                value={formData.email}
                onChange={handleChange}
                sx={{
                  backgroundColor: '#fff',
                  borderRadius: 1,
                  '& .MuiOutlinedInput-root': {
                    color: '#242424',
                    '& fieldset': {
                      borderColor: '#4a5568',
                      transition: 'border-color 0.3s ease',
                    },
                    '&:hover fieldset': {
                      borderColor: '#d32f2f',
                    },
                    '&.Mui-focused fieldset': {
                      borderColor: '#d32f2f',
                    },
                  },
                }}
                InputLabelProps={{
                  sx: {
                    '&.Mui-focused': {
                      color: '#d32f2f',
                    },
                  },
                }}
                InputProps={{ style: { color: '#242424' } }}
              />

              <TextField
                label="Phone Number"
                name="phone"
                fullWidth
                variant="outlined"
                margin="normal"
                value={formData.phone}
                onChange={handleChange}
                sx={{
                  backgroundColor: '#fff',
                  borderRadius: 1,
                  '& .MuiOutlinedInput-root': {
                    color: 'white',
                    '& fieldset': {
                      borderColor: '#4a5568',
                      transition: 'border-color 0.3s ease',
                    },
                    '&:hover fieldset': {
                      borderColor: '#d32f2f',
                    },
                    '&.Mui-focused fieldset': {
                      borderColor: '#d32f2f',
                    },
                  },
                }}
                InputLabelProps={{





                  sx: {
                    '&.Mui-focused': {
                      color: '#d32f2f',





                      
                    },
                  },
                }}
                InputProps={{ style: { color: '#242424' } }}
              />

              <TextField
                label="Your Message"
                name="message"
                fullWidth
                variant="outlined"
                margin="normal"
                multiline
                rows={4}
                value={formData.message}
                onChange={handleChange}
                sx={{
                  backgroundColor: '#fff',
                  borderRadius: 1,
                  '& .MuiOutlinedInput-root': {
                    color: 'white',
                    '& fieldset': {
                      borderColor: '#4a5568',
                      transition: 'border-color 0.3s ease',
                    },
                    '&:hover fieldset': {
                      borderColor: '#d32f2f',
                    },
                    '&.Mui-focused fieldset': {
                      borderColor: '#d32f2f',
                    },
                  },
                }}
                InputLabelProps={{
                  sx: {
                    '&.Mui-focused': {
                      color: '#d32f2f',
                    },
                  },
                }}
                InputProps={{ style: { color: '#242424' } }}
              />
 <Grid item xs={12}>
  <Box   >
    
    <Typography variant="body2" color="textSecondary" style={{ fontSize: '14px', marginTop: '10px' }}>
    <input
      type="checkbox"
      id="terms"
      checked={formData.agreed}
      onChange={(e) =>
        setFormData((prevData) => ({ ...prevData, agreed: e.target.checked }))
      }
      style={{ cursor: 'pointer' ,marginLeft: '0px', marginRight: '7px' }}
    />
      By submitting your contact details, you agree to receive updates, exclusive offers, 
      and important notifications from us. We respect your privacy, and you can opt out anytime. 
      By continuing, you also agree to our{' '}
      <Link to="/privacy-policy" style={{ color: '#d32f2f', textDecoration: 'none' }}>
  Privacy Policy
</Link>
      and{' '}
      <Link to="/terms-and-conditions" target="_blank" style={{ color: '#d32f2f', textDecoration: 'none' }}>
        Terms & Conditions
        </Link>
   
    </Typography>
  </Box>
</Grid>
              <Button
                type="submit"
                variant="contained"
                fullWidth
                sx={{
                  fontSize: '16px',
                  marginTop: '20px',
                  backgroundColor: '#d32f2f',
                  color: 'white',
                  '&:hover': {
                    backgroundColor: '#c62828',
                  },
                }}
              >
                Send Message
              </Button>
            </form>
          </Grid>

          <Grid item xs={12} md={6} sm={6}>
            <Typography
              variant="h4"
              color="primary"
              gutterBottom
              sx={{
                fontFamily: '"Poppins", "Arial", sans-serif',
                fontWeight: 'bold',
                letterSpacing: 0.3,
                textTransform: 'uppercase',
                position: 'relative',
                fontSize: '24px',
                marginBottom: '20px',
                color: '#fff',
                '@media (max-width: 767px)': {
                  fontSize: '18px',
                },
              }}
            >
              Contact Information
            </Typography>
            {/* <Typography
              variant="h6"
              color="primary"
              gutterBottom
              sx={{
                fontFamily: '"Poppins", "Arial", sans-serif',
                fontWeight: 'bold',
                letterSpacing: 0.3,
                color: '#fff',
              }}
            >
              <PhoneIcon /> +1 234 567 890
            </Typography>
            <Typography
              variant="h6"
              color="primary"
              gutterBottom
              sx={{
                fontFamily: '"Poppins", "Arial", sans-serif',
                fontWeight: 'bold',
                letterSpacing: 0.3,
                color: '#fff',
              }}
            >
              <EmailIcon /> example@example.com
            </Typography>
            <Typography
              variant="h6"
              color="primary"
              gutterBottom
              sx={{
                fontFamily: '"Poppins", "Arial", sans-serif',
                fontWeight: 'bold',
                letterSpacing: 0.3,
                color: '#fff',
              }}
            >
              <LocationOnIcon /> 123 Main St, City, Country
            </Typography> */}

            
<Box sx={{ mb: 2 }}>
  <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
    <PhoneIcon sx={{ color: '#d32f2f', mr: 1 }} onClick={handlePhoneClick} />
    <Typography variant="body1" color="white" onClick={handlePhoneClick} sx={{ cursor: 'pointer' }}>
      +1 437 739 0111
    </Typography>
  </Box>
  <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
    <PhoneIcon sx={{ color: '#d32f2f', mr: 1 }} onClick={handlePhoneClick} />
    <Typography variant="body1" color="white" onClick={handlePhoneClick} sx={{ cursor: 'pointer' }}>
      +1 888 927 5557
    </Typography>
  </Box>
</Box>


            <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
              <EmailIcon sx={{ color: '#d32f2f', mr: 1 }} onClick={handleEmailClick} />
              <Typography variant="body1" color="white" onClick={handleEmailClick} sx={{ cursor: 'pointer' }}>
                contact@yndautoparts.com
              </Typography>
            </Box>

            <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
              <LocationOnIcon sx={{ color: '#d32f2f', mr: 1 }} onClick={handleLocationClick} />
              <Typography variant="body1" color="white" onClick={handleLocationClick} sx={{ cursor: 'pointer' }}>
                8316 Tenth Line N, Norval, ON L0P 1K0
              </Typography>
            </Box>

            <Box sx={{ mt: 3, height: '320px', border: '1px solid #ccc' }}>
            <iframe
              title="Google Map"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2889.1377561566696!2d-79.82023342385219!3d43.60367327110451!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x882b6b77e2eb825f%3A0xa993c7a86d89a4e0!2s8316%20Tenth%20Line%20N%2C%20Norval%2C%20ON%20L0P%201K0%2C%20Canada!5e0!3m2!1sen!2sin!4v1729773273606!5m2!1sen!2sin" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"
              width="100%"
              height="100%"



              style={{ border: 0 }}
              allowFullScreen=""
              loading="lazy"
            ></iframe>
          </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default ContactPage;
