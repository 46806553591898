import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Button,
  Container,
  TextField,
  Grid,
  Snackbar,
  Modal,
  Fade,
  IconButton,
  Backdrop,
} from '@mui/material';
import MuiAlert from '@mui/material/Alert';
import emailjs from 'emailjs-com'; 
import Banner1 from "../assets/images/carone.jpg";
import About from './About';
import ProductsPage from './Product';
import Specification from './Specification';
import Footer from "../components/Footer";
import CloseIcon from '@mui/icons-material/Close';
import { Link } from 'react-router-dom';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const Home = () => {
  const [popupOpen, setPopupOpen] = useState(false); // Initially false to delay popup

  const handleClosePopup = () => {
    setPopupOpen(false);
  };

  // Popup delay logic
  useEffect(() => {
    const timer = setTimeout(() => {
      setPopupOpen(true);
    }, 2000); // Delay for 3 seconds

    return () => clearTimeout(timer); 
  }, []);

  // Google Analytics tracking scripts
  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://www.googletagmanager.com/gtag/js?id=AW-16781547707';
    script.async = true;
    document.head.appendChild(script);

    const inlineScript = document.createElement('script');
    inlineScript.innerHTML = `
      window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date());
      gtag('config', 'AW-16781547707');
    `;
    document.head.appendChild(inlineScript);

    return () => {
      if (document.head.contains(script)) {
        document.head.removeChild(script);
      }
      if (document.head.contains(inlineScript)) {
        document.head.removeChild(inlineScript);
      }
    };
  }, []);

  const [formData, setFormData] = useState({
    name: '',
    email: '',
     phone: '',
    message: '',
  });

  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Validate form fields
    if (!formData.name || !formData.email || !formData.message) {
      setSnackbarMessage('Please fill in all fields.');
      setSnackbarSeverity('warning');
      setOpenSnackbar(true);
      return;
    }

    emailjs
      .send(
        'service_xs37ipj',
        'template_ph7df3a',
        formData,
        'TUhvHgQ0Bp4osZuvg'
      )
      .then((response) => {
        console.log('Email sent successfully:', response.status, response.text);

        setFormData({ name: '', email: '', message: '' });
        setSnackbarMessage('Email sent successfully!');
        setSnackbarSeverity('success');
        setOpenSnackbar(true);
      })
      .catch((error) => {
        console.error('Failed to send email:', error);
        setSnackbarMessage('Failed to send email. Please try again.');
        setSnackbarSeverity('error');
        setOpenSnackbar(true);
      });
  };

  return (
    <Box>
      {/* Popup Modal */}
      <Modal
      open={popupOpen}
      onClose={handleClosePopup}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={popupOpen}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '80%',
            maxWidth: 400,
            bgcolor: 'background.paper',
            borderRadius: '10px',
            boxShadow: 24,
            p: 4,
            textAlign: 'center',
          }}
        >

             {/* Close Icon */}
             <IconButton
              aria-label="close"
              onClick={handleClosePopup}
              sx={{
                position: 'absolute',
                top: 8,
                height: '16px',
                width: '16px',
                right: 8,
                color: '#000',
              }}
            >
              <CloseIcon />
            </IconButton>

          <Typography
            variant="h6"
            sx={{
              textAlign: 'left',
              fontSize: '18px',
              fontWeight: '700',
              transform: 'translateY(-20px)',
              color: '#fff',
              marginBottom: '10px',
            }}
          >
            Can't Find the Part You Need?
          </Typography>

          <Typography
            variant="h4"
            sx={{
              textAlign: 'left',
              fontSize: '18px',
              fontWeight: '700',
              marginBottom: '10px',
            }}
          >
            Let Us Help!
          </Typography>

          <Typography
            variant="body1"
            sx={{
              lineHeight: '26px',
              textAlign: 'left',
              color: '#333',
              fontSize: '14px',
              fontWeight: '500',
              marginBottom: '0px',
            }}
          >
            Looking for a specific engine, auto part, or accessory? Call us now and let our experts assist you in finding exactly what you're looking for.
            We ship across the US and Canada!
          </Typography>

          <Button
            type="button"
            variant="contained"
            color="error"
            onClick={() => window.location.href = 'tel:+18889275557'}
            sx={{
              mt: 2,
              px: 3,
              textAlign: 'left',
              fontSize: '12px',
              justifyContent: 'flex-start',
              display: 'flex',
            }}
          >
            Call Us
          </Button>

        </Box>
      </Fade>
    </Modal>

      {/* Site Content */}
      {!popupOpen && (
        <>
          <Box position="relative" bgcolor="black">
            <Box
              component="img"
              src={Banner1}
              alt="Banner image"
              width="100%"
              sx={{
                objectFit: 'cover',
                opacity: 0.5,
                height: { xs: 'auto', md: '100vh' },
                display: { xs: 'none', sm: 'none', md: 'block' },
              }}
            />
            <Box
              sx={{
                position: 'absolute',
                bottom: 0,
                left: 0,
                width: '100%',
                background: 'linear-gradient(to bottom right, #FF0000, #FF6347)',
                clipPath: 'polygon(0% 100%, 100% 100%, 100% 0%, 0% 60%)',
                zIndex: -1,
              }}
            />
            <Box
              position={{ xs: 'unset', md: 'absolute' }}
              top={0}
              left={0}
              right={0}
              bottom={0}
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <Container>
                <Grid container spacing={4} alignItems="center">
                  {/* Left Side: Text Section */}
                  <Grid item xs={12} md={6}>
                    <Box sx={{ padding: { xs: '32px 0px 0px', md: '32px' } }}>
                      <Typography
                        variant="h4"
                        color="error"
                        fontWeight="bold"
                        textAlign="left"
                        marginBottom="25px"
                        sx={{ fontSize: { xs: '1.5rem', sm: '2rem', md: '24px' } }}
                      >
                        Reliability
                      </Typography>
                      <Typography
                        variant="h2"
                        color="white"
                        fontWeight="bold"
                        textAlign="left"
                        sx={{ fontSize: { xs: '2rem', sm: '2.5rem', md: '3.5rem' } }}
                      >
                        Quality You Can Trust, Nationwide Delivery
                      </Typography>
                      <Typography
                        variant="h6"
                        color="white"
                        paddingTop="15px"
                        textAlign="left"
                        mt={1}
                        sx={{ fontSize: { xs: '0.9rem', sm: '1rem', md: '18px' } }}
                      >
                        Top-quality used engines and auto parts delivered across Canada and the US. Get reliable parts at competitive prices, shipped fast to keep you moving.
                      </Typography>
                    </Box>
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    md={6}
                    sx={{ padding: { xs: '0px 0px 52px', md: '32px', } }}
                  >
                    <Box
                      component="form"
                      onSubmit={handleSubmit}
                      sx={{
                        backgroundColor: '#ffffffb0',
                        padding: { xs: '16px', md: '32px' },
                        borderRadius: '10px',
                        position: 'relative',
                        zIndex: 0,
                        '&::before': {
                          content: '""',
                          position: 'absolute',
                          background: '#d32f2f',
                          width: '100%',
                          height: '20%',
                          left: 0,
                          top: 0,
                          borderRadius: '0% 0% 250% 0%',
                          zIndex: -1,
                        },
                      }}
                    >
                      <Typography
                        variant="h5"
                        color="#fff"
                        textAlign="center"
                        marginBottom="20px"
                      >
                        Contact Us
                      </Typography>

                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <TextField
                            fullWidth
                            name="name"
                            label="Your Name"
                            variant="outlined"
                            value={formData.name}
                            onChange={handleInputChange}
                            InputLabelProps={{ style: { color: '#242424' } }}
                            InputProps={{ style: { color: '#242424' } }}
                            sx={{
                              '& .MuiOutlinedInput-root': {
                                '& fieldset': { borderColor: '#242424' },
                                '&:hover fieldset': { borderColor: '#242424' },
                                '&.Mui-focused fieldset': { borderColor: '#d32f2f' },
                              },
                            }}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <TextField
                            fullWidth
                            name="email"
                            label="Your Email"
                            variant="outlined"
                            value={formData.email}
                            onChange={handleInputChange}
                            InputLabelProps={{ style: { color: '#242424' } }}
                            InputProps={{ style: { color: '#242424' } }}
                            sx={{
                              '& .MuiOutlinedInput-root': {
                                '& fieldset': { borderColor: '#242424' },
                                '&:hover fieldset': { borderColor: '#242424' },
                                '&.Mui-focused fieldset': { borderColor: '#d32f2f' },
                              },
                            }}
                          />
                        </Grid>
                        <Grid item xs={12}>
                   
                   <TextField
                     fullWidth
                     name="phone"
                     label=" Phone Number"
                     variant="outlined"
                     type="tel" // Ensures it's for phone numbers
                     value={formData.phone}
                     onChange={handleInputChange}
                     InputLabelProps={{ style: { color: '#242424' } }}
                     InputProps={{ style: { color: '#242424' } }}
                     sx={{
                       '& .MuiOutlinedInput-root': {
                         '& fieldset': { borderColor: '#242424' },
                         '&:hover fieldset': { borderColor: '#242424' },
                         '&.Mui-focused fieldset': { borderColor: '#d32f2f' },
                       }
                     }}
                   />
                 </Grid>


                        <Grid item xs={12}>
                          <TextField
                            fullWidth
                            name="message"
                            label="Your Message"
                            variant="outlined"
                            value={formData.message}
                            onChange={handleInputChange}
                            multiline
                            rows={4}
                            InputLabelProps={{ style: { color: '#242424' } }}
                            InputProps={{ style: { color: '#242424' } }}
                            sx={{
                              '& .MuiOutlinedInput-root': {
                                '& fieldset': { borderColor: '#242424' },
                                '&:hover fieldset': { borderColor: '#242424' },
                                '&.Mui-focused fieldset': { borderColor: '#d32f2f' },
                              },
                            }}
                          />
                        </Grid>
                      </Grid>
                             <Grid item xs={12}>
                              <Box   >
                                
                                <Typography variant="body2" color="textSecondary" style={{ fontSize: '14px', marginTop: '10px' }}>
                                <input
                                  type="checkbox"
                                  id="terms"
                                  checked={formData.agreed}
                                  onChange={(e) =>
                                    setFormData((prevData) => ({ ...prevData, agreed: e.target.checked }))
                                  }
                                  style={{ cursor: 'pointer' ,marginLeft: '0px', marginRight: '7px' }}
                                />
                                  By submitting your contact details, you agree to receive updates, exclusive offers, 
                                  and important notifications from us. We respect your privacy, and you can opt out anytime. 
                                  By continuing, you also agree to our{' '}
                                  <Link to="/privacy-policy" style={{ color: '#d32f2f', textDecoration: 'none' }}>
                              Privacy Policy
                            </Link>
                                  and{' '}
                                  <Link to="/terms-and-conditions" target="_blank" style={{ color: '#d32f2f', textDecoration: 'none' }}>
                                    Terms & Conditions
                                    </Link>
                               
                                </Typography>
                              </Box>
                            </Grid>
                      <Button
                        type="submit"
                        variant="contained"
                        color="error"
                        sx={{ marginTop: '20px', padding: '10px 20px' }}
                      >
                        Send
                      </Button>
                    </Box>
                  </Grid>
                </Grid>
              </Container>
            </Box>
          </Box>

          <About showSpecification={false} isStandalone={false} showButton={true} />
          <ProductsPage />
          <Snackbar
            open={openSnackbar}
            autoHideDuration={6000}
            onClose={() => setOpenSnackbar(false)}
          >
            <Alert
              onClose={() => setOpenSnackbar(false)}
              severity={snackbarSeverity}
            >
              {snackbarMessage}
            </Alert>
          </Snackbar>
          <Specification />
        </>
      )}
    </Box>
  );
};

export default Home;
